<script>
import VxDialogView from "@/components/vx/VxDialogView";

import { mapGetters, mapMutations } from "vuex";
import { CREATE_NOTE } from "@/graphql/note/mutations.gql";
import { DAY_NOTES } from "@/graphql/note/queries.gql";

import { addDays, format } from "date-fns";
import { hasGlobalLoaderAccess } from "@/mixins/ui";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { hasActiveEmployeeIdAccess } from "@/mixins/employee";
import { parse } from "@/utils/timelanguage";

import RaiDatePicker from "@/components/util/RaiDatePicker";
import NoteEditor from "@/components/daybook/NoteEditor";

export default {
  name: "NewNoteDialog",
  components: {
    RaiDatePicker,
    VxDialogView,
    EmojiPickerMenu: () =>
      import("@/views/Texting/AppView/components/EmojiPickerMenu"),
    NoteEditor,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [hasGlobalLoaderAccess, hasSnackbarAccess, hasActiveEmployeeIdAccess],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // This doesn't seem to be used.
    noteBody: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      body: null,
      note: {},
      isManagerOnly: false,
      loadingKey: "add-note",
      // Speficy the business date of the note
      isDifferentDay: false,
      businessDate: format(new Date(), "yyyy-MM-dd"),
      // Speficy the end date of the note
      setEndDate: false,
      endDate: format(addDays(new Date(), 3), "yyyy-MM-dd"),
      content: "",
      search: "",
      minDate: format(new Date(), "yyyy-MM-dd"),
    };
  },
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) setTimeout(this.reset, 300);
        this.$emit("input", v);
      },
    },
    disabled() {
      if (!this.body) return true;
      return this.body && this.body.length < 1;
    },
  },
  created() {
    window.timeParse = parse;
  },
  methods: {
    ...mapMutations("ui", ["loading", "loaded"]),
    addEmoji(emoji) {
      this.body += emoji.native;
      document.getElementsByClassName("editr--content")[0].focus();
    },
    addNote() {
      const vm = this;
      this.loading(this.loadingKey);
      this.$apollo
        .mutate({
          mutation: CREATE_NOTE,
          variables: {
            input: {
              body: this.body,
              businessDate: this.businessDate,
              endDate: this.endDate,
              isManagerOnly: this.isManagerOnly,
              employeeId: this.activeEmployeeId,
            },
          },
          update(
            store,
            {
              data: {
                createNote: { note },
              },
            }
          ) {
            const variables = {
              businessDate: note.businessDate,
              storeId: vm.$route.params.storeId,
            };
            const data = store.readQuery({
              query: DAY_NOTES,
              variables,
            });
            if (data) {
              data.notes = [note, ...data.notes];
              store.writeQuery({ query: DAY_NOTES, variables, data });
            }
          },
        })
        .then(() => true)
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while creating the note. Error: ${error}`,
          });
          return false;
        })
        .then((successful) => {
          this.reset();
          if (successful) this.$router.go(-1);
        });
    },
    reset() {
      this.body = null;
      this.businessDate = format(new Date(), "yyyy-MM-dd");
      this.endDate = format(addDays(new Date(), 3), "yyyy-MM-dd");
      this.isManagerOnly = false;
      this.loaded(this.loadingKey);
    },
    sendNote(v, d) {
      console.log("sendNote called");
      console.log(v);
      console.log(d);
    },
  },
};
</script>

<template>
  <VxDialogView title="Leave a note" data-testid="newNoteDialog">
    <v-container>
      <v-form>
        <v-row no-gutters>
          <v-col cols="12">
            <NoteEditor
              v-model="body"
              data-testid="newNoteDialogBodyField"
              style="font-size: 15px"
            />
            <EmojiPickerMenu @select="addEmoji" />
          </v-col>
        </v-row>
        <!-- Business date -->
        <v-row justify="start" class="mt-4 no-gutter">
          <v-col cols="6">
            <rai-date-picker
              v-model="businessDate"
              class="mr-4"
              label="Start date"
              data-testid="newNoteDialogStartDateField"
              hint="The day this note will show up"
              :min="minDate"
              persistent-hint
            />
          </v-col>
          <v-col>
            <rai-date-picker
              v-model="endDate"
              label="End date"
              data-testid="newNoteDialogEndDateField"
              hint="The last day this note will show"
              :min="minDate"
              persistent-hint
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-model="isManagerOnly"
          data-testid="newNoteDialogManagerOnlyCheckbox"
          class="mt-4"
          label="Manager only"
        ></v-checkbox>
      </v-form>
      <v-row class="mx-2 justify-end">
        <v-btn
          data-testid="newNoteDialogAddBtn"
          :disabled="disabled"
          :loading="isLoading(loadingKey)"
          text
          color="primary"
          @click="addNote"
          v-text="`Add note`"
        />
      </v-row>
    </v-container>
  </VxDialogView>
</template>

<style>
.w-128 {
  width: 32rem;
}
.h-96 {
  height: 24rem;
}
.t-4 {
  top: 1rem;
}
.-r-64 {
  right: -16rem;
}

.emoji-invoker {
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}

.emojis:after {
  content: "";
  flex: auto;
}
.editr--content[contenteditable="true"]:empty:before {
  color: inherit;
}
</style>
